import logo from "./logo.webp"

const  clientData = {
   client_entity: 6,
   attorney_firm: 'bbelawyers.com',
    attorney_name: 'Adam Emerson',
    attorney_number: '662-393-4450',
    attorney_email: 'adam@bbelawyers.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_bridgeforth_buntin+(720p).mp4',
    mainColor: '#8a353d',
    secondaryColor: '#972C3B',
    siteLink: 'http://www.bbelawyers.com',
    logo
}

export default clientData